<template>  
  <div>
    <v-btn
      @click="adicionando = true"
      color="success"
      small>
      <v-icon>mdi-plus</v-icon>
      {{$i18n.t('AdicionarOcorrencia')}}
    </v-btn>

    <v-dialog
      v-model="adicionando"
      persistent
      max-width="600px">
      
      <v-card>
        <v-card-title>
          <span class="text-h5">{{$i18n.t('NovaOcorrencia')}}</span>
        </v-card-title>
        
        <v-card-text>
          <ocorrencia
            ref="novaOcorrencia"
            :documento="documento"
            @close="adicionando = false"
            @salva="salva">
          </ocorrencia>
        </v-card-text>
        
      </v-card>
    </v-dialog>
    
  </div>
</template>

<script>
import Ocorrencia from './Ocorrencia.vue';
export default {
  components: { Ocorrencia },
    props: ['documento'],
    data() {
      return {
        adicionando: false
      }
    },
    methods: {
      salva(ocorrencia) {
        this.adicionando = false;
        this.$emit('salva', ocorrencia);
      }
    }
  }
</script>