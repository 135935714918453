<template>  
  <div>

    <v-progress-circular
      indeterminate
      color="red"
      v-if="loading">
    </v-progress-circular>
    <span v-if="loading">
      Salvando dados...
    </span>

     <v-form
        ref="form"
        v-model="valido"
        v-if="!loading"
        lazy-validation
        class="compact">

        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              ref="dataOcorrencia"
              v-model="ocorrencia.data"
              :rules="dataRules"
              :label="$i18n.t('DataOcorrencia')"
              v-mask="'##/##/#### ##:##:##'"
              required>
            </v-text-field>
          </v-col>
        </v-row>
        
        <v-row>
          <v-col cols="12" sm="12">
            <v-select
              v-model="ocorrencia.ocorrencia"
              :rules="requiredRules"
              item-text="nome"
              item-value="id"
              :items="ocorrencias"
              :label="$i18n.t('TipoOcorrencia')"
              required
              dense>
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12">
            <v-textarea
              ref="descricao"
              :rows="2"
              v-model="ocorrencia.descricao"
              :label="$i18n.t('DescricaoOcorrencia')">
            </v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12">
            <v-select
              v-model="ocorrencia.situacao"
              :rules="requiredRules"
              item-text="nome"
              item-value="id"
              :items="situacoes"
              :label="$i18n.t('Situacao')"
              required
              dense>
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12">
            <anexo
              v-model="ocorrencia.anexo">
            </anexo>
          </v-col>
        </v-row>
     </v-form>

     <div class="w100" v-if="!loading">
        <v-row>
          <v-col align="center" cols="6">
            <v-btn
              color="success"
              elevation="2"
              @click="salva"
              :disabled="!validateForm">
              <v-icon>
                mdi-check
              </v-icon>
              {{$i18n.t('Salvar')}}
            </v-btn>
          </v-col>
          <v-col align="center" cols="6">
            <v-btn
              elevation="0"
              @click="$emit('close')">
              {{$i18n.t('Cancelar')}}
            </v-btn>
          </v-col>
        </v-row>
      </div>
  </div>
</template>

<script>
import { Geolocation } from '@capacitor/geolocation';
import Anexo from './Anexo.vue';
export default {
  components: { Anexo },
    props: ['documento'],
    data: () => ({
      loading: false,
      ocorrencia: {
        "situacao": "Iniciado"
      },
      valido: false,
      requiredRules: [
        v => !!v
      ],
      dataRules: [ 
        v => !!v,
        v => (v && v.length == 19) || 'dd/mm/aaaa hh:mi:ss',
      ],
      modelos: null,
      coords: null,
      ocorrencias: [],
      situacoes: []
    }),
    computed: {
        validateForm() {          
          return this.ocorrencia
            && this.ocorrencia.data != null
            && this.ocorrencia.data != ''
            && this.ocorrencia.ocorrencia != null
            && this.ocorrencia.situacao != null;
        }
    },
    methods: {
      salva() {
        let valido = this.$refs.form.validate();
        if (this.validateForm && valido) {
          this.loading = true;

          let data = {
            "data": this.ocorrencia.data,
            "documentoId": this.documento.id,
            "ocorrenciaId": this.ocorrencia.ocorrencia,
            "situacaoId": this.ocorrencia.situacao,
            "descricao": this.ocorrencia.descricao,
            "anexo": this.ocorrencia.anexo,
            "origem": "APP"
          };

          if (this.coords) {
            data.latitude = this.coords.latitude;
            data.longitude = this.coords.longitude;
          }

          this.$http.post('/api/doc/documento/ocorrencias', data)
              .then((req) => {
                if (req.data.sucesso) {
                  this.ocorrencia = req.data.objeto;
                  this.$msg.sucesso(this.$i18n.t('Sucesso'));
                  this.$emit('salva', this.ocorrencia);
                  this.ocorrencia = {};
                } else
                  this.$msg.erro(req.data.mensagem);
              })
              .catch((e) => {
                this.$msg.erro(e.response.data.message);
              })
              .finally(() => {
                this.loading = false;
              });
        } else {
          // this.focus();
        }
      },
      load() {
        this.loadOcorrencias();
        this.loadSituacoes();
        this.loadModelos();
      },
      loadModelos() {
        this.$http.get('/api/doc/ocorrencia/modelos')
          .then((req) => {
            this.modelos = req.data;
            if (this.modelos.length > 0)
              this.carregaDeModelo(this.modelos[0]);
          })
          .catch((e) => {
            this.$msg.erro(e.response.data.message);
          });
      },
      carregaDeModelo(modelo) {
        
        let data = modelo.hoje ? this.$moment().format('DD/MM/YYYY') : modelo.data;
        let hora = modelo.agora ? this.$moment().format('HH:mm:ss') : modelo.hora;

        this.ocorrencia.data = new String(data + ' ' + hora).trim();

        this.ocorrencia.ocorrencia = modelo.ocorrencia ? (modelo.ocorrencia.id || modelo.ocorrencia) : null;
        this.ocorrencia.situacao = modelo.situacao ? (modelo.situacao.id || modelo.situacao) : null;
        this.ocorrencia.descricao = modelo.descricao;
        // this.focus();
      },
      loadOcorrencias() {
        this.$http.get('/api/doc/tipo_ocorrencias/select')
          .then((req) => {
              this.ocorrencias = req.data;
          })
          .catch((e) => {
            this.$msg.erro(e.response.data.message);
          });
      },
      loadSituacoes() {
        this.$http.get('/api/doc/situacoes/select')
          .then((req) => {
              this.situacoes = req.data;
          })
          .catch((e) => {
            this.$msg.erro(e.response.data.message);
          });
      },
      focus() {
        const self = this;
        setTimeout(function() {
          self.$refs.dataOcorrencia.focus();
        }, 200);
      }
    },
    mounted() {
      Geolocation.getCurrentPosition()
        .then((res) => {
          this.coords = res.coords;
        })
        .catch((e) => {
          console.error(e);
        });
      // this.focus();
      this.load();
    }
  }
</script>
<style lang="scss" scoped>
  .compact {
    .col-12 {
      padding: 2px;
    }
    .v-input {
      font-size: 14px;
    }
  }
</style>