<template>
  <div class="code-dialog">

    <v-progress-circular
      indeterminate
      color="red"
      v-if="loading">
    </v-progress-circular>

    <div class="text-h6" v-if="code">Codigo: {{ code }}</div>
    
    <div class="scanner-container">
      <div v-show="!loading">
        <video poster="data:image/gif,AAAA" ref="scanner"></video>
        <div :class="`overlay-element ${format}`"></div>
        <div :class="`laser ${format}`"></div>
      </div>
    </div>

    <div class="tools">          
      <v-btn
        @click="$emit('changeDevice')"
        dark>
        <v-icon>
          mdi-sync
        </v-icon>
      </v-btn>

      <v-btn
        @click="close"
        dark>
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>

      <v-icon v-if="format == 'barcode'">mdi-barcode</v-icon>
      <v-icon v-if="format == 'qrcode'">mdi-qrcode</v-icon>
    </div>

    <small v-if="error && perfil == 'ADMIN'">{{ error }}</small>

  </div>
</template>
<script>
import { BrowserMultiFormatReader, DecodeHintType, BarcodeFormat } from "@zxing/library";
export default {
  props: ['device', 'options', 'tipo', 'format'],
  data() {
      return {
        loading: true,
        code: null,
        index: 0,
        codeReader: null,
        error: null,
        perfil: null
      }
  },
  watch: {
    device() {
      this.loading = true;
      this.onStop();
      this.init();
    }
  },
  methods: {
    init() {
      this.codeReader.decodeFromVideoDevice(this.device.deviceId, this.$refs.scanner, (result, error) => {
        this.loading = false;
        if (result) {
          this.onDetected(result);
          this.error = result.text;
        }
        // if (error && error != 'NotFoundException: No MultiFormat Readers were able to detect the code.')
        if (error)
          this.error = error;
      });
    },
    onDetected(data) {
      try {
        
        if (Array.isArray(data))
          data = data[0];

        let valid = true;
        //if (this.options && this.options.validate)
          //valid = this.options.validate(this.tipo, data.text);

        if (valid) {
          this.code = data.text;
          this.$emit('onDecode', this.code);
          this.close();
        }
      } catch(e) {
        this.error = e;
      }
    },
    onStop() {
      try {
        this.codeReader.stopContinuousDecode();
        this.codeReader.reset();
      } catch (e) {
        this.error = e;
      }
    },
    close() {
      this.onStop();
      this.$emit('close');
    }
  },
  destroyed() {
    this.codeReader.reset();
    this.codeReader.stopContinuousDecode();
  },
  mounted() {
    let info = this.$auth.info();
    if (info)
      this.perfil = info.perfil;

      this.onStop();
    
    let formats = [];
    switch(this.format) {
      case 'qrcode':
        formats = [ BarcodeFormat.QR_CODE ];
        break;
      case 'barcode':
        if (this.tipo == 1)
          formats = [ BarcodeFormat.CODE_128 ];
        else
          formats = [ BarcodeFormat.CODE_128, BarcodeFormat.CODE_39 ];
        break;
        
      default:
        formats = [
          BarcodeFormat.QR_CODE,
          BarcodeFormat.CODE_128,
          BarcodeFormat.CODE_39
        ];
        break;
    }
        
    const hints = new Map();
    hints.set(DecodeHintType.ASSUME_GS1, true)
    hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
    hints.set(DecodeHintType.TRY_HARDER, true);

    this.codeReader = new BrowserMultiFormatReader(null, hints);
    
    //this.codeReader.listVideoInputDevices()
    //  .then((res) => {
    //    this.devices = res;
    //    console.log(this.devices);
    //});


    this.$refs.scanner.oncanplay = () => {};
    this.$refs.scanner.onerror = (e) => { this.error = e; };
    this.init();
  }
}
</script>
<style scoped lang="scss">
.code-dialog {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  background: white;
  text-align: center;
  padding-bottom: 4px;
  width: 100%;
  min-height: 200px;
  .scanner-container {
    max-width: 800px;
  }
  .tools {
    width: 100%;
  }
  .scanner-container {
    position: relative;
  }
  .overlay-element {
    position: absolute;
    top: 0;
    width: 100%;
    height: 99%;
    background: rgba(30,30,30,0.5);
    
    &.barcode {
      clip-path: polygon(0% 0%, 100% 0%, 100% 36%, 0 36%, 0% 76%, 0% 100%, 100% 99%, 100% 69%, 0 69%);
    }

    &.qrcode {
      -webkit-clip-path: polygon(0% 0%, 0% 100%, 20% 100%, 20% 20%, 80% 20%, 80% 80%, 20% 80%, 20% 100%, 100% 100%, 100% 0%);
      clip-path: polygon(0% 0%, 0% 100%, 20% 100%, 20% 20%, 80% 20%, 80% 80%, 20% 80%, 20% 100%, 100% 100%, 100% 0%);
    }
  }
  .laser {
    width: 60%;
    margin-left: 20%;
    background-color: tomato;
    height: 1px;
    position: absolute;
    top: 40%;
    z-index: 2;
    box-shadow: 0 0 4px red;
    &.barcode {
      margin-left: 5%;
      width: 90%;
      top: 50%;
    }
    &.qrcode {
      -webkit-animation: scanning 2s infinite;
      animation: scanning 2s infinite;
    }
  }
  @-webkit-keyframes scanning {
    50% {
      -webkit-transform: translateY(75px);
      transform: translateY(75px);
    }
  }
  @keyframes scanning {
    50% {
      -webkit-transform: translateY(75px);
      transform: translateY(75px);
    }
  }
}
</style>
<style lang="scss">
  video {
    width: 100%;
    max-height: calc(100vh - 30px);
    height: auto;
  }
  canvas {
    display: none;
  }
</style>