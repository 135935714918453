<template>  
  <div class="compact"> 

      <v-row v-if="content.tipo == 'IMAGE'">
        <v-col align="center" cols="6" sm="6">
          <v-btn @click="remove">
            {{$i18n.t('Remover')}}
          </v-btn>
        </v-col>
        <v-col align="center" cols="6" sm="6">
          <div class="flex" slot="append">

            <v-btn icon @click="$refs.file.click()">
              <v-icon>
                mdi-upload
              </v-icon>
            </v-btn>

            <camera-capture @capture="capture" v-if="!tipo || tipo == 0"></camera-capture>
            <camera-capture-ios @capture="capture" v-if="tipo == 1"></camera-capture-ios>
          </div>
        </v-col>
      </v-row>
        
      <v-row>
        <v-col cols="12" sm="12">

          <v-text-field
            refs="link"
            v-model="content.conteudo"
            :label="$i18n.t('Link')"
            required
            v-if="content.tipo == 'LINK'"
            @change="$emit('input', content)">

            <div class="flex" slot="append">
              <v-btn icon @click="$refs.file.click()">
                <v-icon>
                  mdi-upload
                </v-icon>
              </v-btn>
              
              <camera-capture @capture="capture" v-if="!tipo || tipo == 0"></camera-capture>
              <camera-capture-ios @capture="capture" v-if="tipo == 1"></camera-capture-ios>
            </div>
          </v-text-field>

          <img :src="content.conteudo" width="100%" v-if="content.tipo == 'IMAGE'">
        </v-col>

      </v-row>

      <input
        ref="file"
        type="file"
        @change="upload"
        accept="image/*"
        style="display:none"/>
        
  </div>
</template>

<script>
import CameraCapture from '@/components/CameraCaptureX.vue';
import CameraCaptureIos from '@/components/CameraCaptureIos.vue';
export default {
  components: { CameraCapture, CameraCaptureIos },
  props: ['value'],
  data() {
    return {
      content: this.value || {
        tipo: 'LINK'
      },
      tipo: window.CAMERA_TYPE
    }
  },
  methods: {
    upload(e) {
      
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
          return;

      for (let i = 0; i < files.length; i++) {
        let name = files[i].name;
        let header = `data:application/${name.split('.').pop()};base64,`;
        this.uploadToBase64(files[i])
            .then((base64) => {

              if (window.COMPACTA) {
                this.resizeBase64Image(header + base64)
                  .then((res) => {
                    this.content = {
                      tipo: 'IMAGE',
                      conteudo: res,
                      modificado: true
                    };
                    this.$emit('input', this.content);
                  });
              }
              else {
                this.content = {
                  tipo: 'IMAGE',
                  conteudo: header + base64,
                  modificado: true
                };
                this.$emit('input', this.content);
              }
            });
      }
    },
    uploadToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
              if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
              }
              resolve(encoded);
            };
            reader.onerror = error => reject(error);
        });
    },
    resizeBase64Image(base64Image) {
      return new Promise((resolve) => {
        const maxSizeInMB = window.MAX_SIZE_MB;
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
        const img = new Image();
        img.src = base64Image;
        img.onload = function () {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext('2d');
          const width = img.width;
          const height = img.height;
          const aspectRatio = width / height;
          const newWidth = Math.sqrt(maxSizeInBytes * aspectRatio);
          const newHeight = Math.sqrt(maxSizeInBytes / aspectRatio);
          canvas.width = newWidth;
          canvas.height = newHeight;
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
          let quality = 0.8;
          let dataURL = canvas.toDataURL('image/jpeg', quality);
          resolve(dataURL);
        };
      });
    },
    remove() {
      this.content = {
        tipo: 'LINK'
      };
      this.$emit('input', this.content);
    },
    capture(base64) {
      this.content = {
        tipo: 'IMAGE',
        conteudo: base64,
        modificado: true
      };
      this.$emit('input', this.content);
    }
  }
}
</script>
<style lang="scss" scoped>
  .compact {
    .v-input {
      font-size: 14px;
      padding-top: 2px;
    }
  }
</style>