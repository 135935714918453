<template>
  <div>
    
    <v-btn
      @click="show"
      icon>
      <v-icon>
        mdi-camera
      </v-icon>
    </v-btn>
    
    <v-dialog
      v-model="capturando"
      persistent
      max-width="640">

      <div class="cam-dialog">

        {{ message }}

        <div class="text-center mg-10" v-if="loading">
          <v-progress-circular
            indeterminate
            color="red">
          </v-progress-circular>
        </div>

        <video
          ref="video"
          class="webcam"
          :width="size.w"
          :height="size.h"
          autoplay
          v-if="capturando && loaded"
          v-show="!base64">
        </video>

        <canvas
          ref="canvas"
          :width="canvaSize.width"
          :height="canvaSize.height">
        </canvas>
        
      <!--
        <WebCam
          ref="webcam"
          :width="size.w"
          :height="size.h"
          class="webcam"
          @started="loading = false"
          @camera-change="onCameraChange"
          @error="error"
          :deviceId="deviceId"
          v-if="capturando && loaded"
          v-show="!base64">
        </WebCam>
-->
        <img :src="base64" v-if="base64">

        <div class="tools">

          <v-btn
            @click="changeCamera"
            dark
            v-if="!loading && base64 == null && cameras && cameras.length > 1">
            <v-icon>
              mdi-sync
            </v-icon>
          </v-btn>

          <v-btn
            @click="salva"
            color="green"
            dark
            v-if="base64">
            <v-icon>
              mdi-check
            </v-icon>
          </v-btn>

          <v-btn
            @click="base64 = null"
            color="red"
            dark
            v-if="base64">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>

          <v-btn
            @click="capture"
            dark
            v-if="!loading && base64 == null">
            <v-icon>
              mdi-camera
            </v-icon>
          </v-btn>

          <v-btn
            @click="close"
            dark
            v-if="base64 == null">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </div>

      </div>

    </v-dialog>
  </div>
</template>
<script>
// import { WebCam } from 'vue-cam-vision'
// import WebCam from './WebCam.vue';
export default {
  //components: { WebCam },
  name: 'camera-capture',
  data() {
      return {
        capturando: false,
        loading: true,
        captures: [],
        imgReport: [],
        webcam: null,
        img: null,
        cameras: [],
        camera: null,
        index: 0,
        base64: null,
        message: null,
        deviceId: null,
        stream: null,
        canvaSize: {
          width: window.PHOTO_WIDTH ? window.PHOTO_WIDTH : 1024,
          height: window.PHOTO_HEIGHT ? window.PHOTO_HEIGHT : 768
        }
      }
  },
  computed: {
    size() {
      return {
        w: '100%',
        h: '100%'
      }
    }
  },
  methods: {
    show() {
      this.loading = true;
      this.capturando = true;
      this.updateVideoSource();
      this.loading = false;
    },
    //onCameras(cameras) {
      //this.cameras = cameras;
      //this.changeCamera();
    //},
    changeCamera() {
      this.index++;
      if (this.index >= this.cameras.length)
        this.index = 0;
      this.deviceId = this.cameras[this.index].deviceId;
      this.updateVideoSource();
    },
    onCameraChange() {},
    capture() {
      
      //this.$refs.webcam.capture()
      //  .then((res) => {
      //    this.base64 = res;
      //    this.salva();
      //  })
      //  .catch(e => this.$msg.erro(e));              

      let video = this.$refs.video;
      let canvas = this.$refs.canvas;
      
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
      this.base64 = canvas.toDataURL('image/jpeg');
      this.salva();

    },
    resizeBase64Image(base64Image) {
      return new Promise((resolve) => {
        const maxSizeInMB = window.MAX_SIZE_MB;
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
        const img = new Image();
        img.src = base64Image;
        img.onload = function () {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext('2d');
          const width = img.width;
          const height = img.height;
          const aspectRatio = width / height;
          const newWidth = Math.sqrt(maxSizeInBytes * aspectRatio);
          const newHeight = Math.sqrt(maxSizeInBytes / aspectRatio);
          canvas.width = newWidth;
          canvas.height = newHeight;
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
          let quality = 0.8;
          let dataURL = canvas.toDataURL('image/jpeg', quality);
          resolve(dataURL);
        };
      });
    },
    fileSize(base64) {
      let buffer = Buffer.from(base64.substring(base64.indexOf(',') + 1));
      return buffer.length / 1e+6;
    },
    salva() {
      this.resizeBase64Image(this.base64)
        .then((res) => {
          this.base64 = res;
          this.$emit('capture', this.base64);
          this.close();
        });

      // this.$emit('capture', this.base64);
      // this.close();
    },
    close() {
      // this.$refs.webcam.stop();
      this.stopVideo();
      this.capturando = false;
      this.base64 = null;
    },
    error(e) {
      this.loading = false;
      this.message = e;
    },
    updateVideoSource() {
      if (this.deviceId != null) {
        this.$nextTick(() => {
          if (this.$refs.video) {
            if (this.stream)
              this.stopVideo();
            let constraints = {
              video: {
                deviceId: { exact: this.deviceId }
              }
            };
            navigator.mediaDevices.getUserMedia(constraints)
              .then((stream) => {
                this.stream = stream;
                this.$refs.video.srcObject = stream;
              });
            
          }
        });
      }
    },
    stopVideo() {
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop());
        this.$refs.video.srcObject = null;
      }
    }
  },
  mounted() {
    this.loading = true;
    navigator.mediaDevices.getUserMedia({video:true})
        .then((stream) => {
          
          stream.getTracks().forEach((track) => {
            if (track.kind == 'video')
              track.stop();
          });
        
          navigator.mediaDevices.enumerateDevices()
            .then((devices) => {
              this.index = -1;
              let nIndex = 0;

              if (devices && devices.length > 0) {
                devices.forEach((device) => {
                  // if ( device.kind === 'videoinput' && device.label.match(/back/) != null ) {
                  if (device && device.deviceId != "" && device.kind === 'videoinput') {

                    this.cameras.push(device);
                    nIndex = this.cameras.length - 1;

                    if (device.label.match(/back/) != null)
                      this.index = this.cameras.length - 1;
                    
                  }
                })

                if (this.index < 0)
                  this.index = nIndex;

                this.deviceId = this.cameras[this.index].deviceId;               
                this.loaded = true;
              }
              
            });

        })
        .catch( (err) =>{
          console.error(err);
        });
  }
}
</script>
<style scoped lang="scss">
.cam-dialog {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  background: white;
  text-align: center;
  padding: 14px;

  .tools {
    width: 100%;
  }
}
</style>