<template>
  <div class="code-dialog">

    <v-progress-circular
      indeterminate
      color="red"
      v-if="loading">
    </v-progress-circular>

    <div class="text-h6" v-if="error">{{ error }}</div>
    <div class="text-h6" v-if="code">Codigo: {{ code }}</div>
    <div id="scan" v-show="!loading"></div>
    <div class="overlay-element" v-show="!loading"></div>
    <div class="laser" v-show="!loading"></div>

    <div class="tools">          
      <v-btn
        @click="$emit('changeDevice')"
        dark>
        <v-icon>
          mdi-sync
        </v-icon>
      </v-btn>

      <v-btn
        @click="close"
        dark>
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </div>

  </div>
</template>
<script>
import Quagga from 'quagga';
export default {
  props: ['device', 'options', 'tipo'],
  data() {
      return {
        loading: true,
        code: null,
        index: 0,
        error: null,
        area: { 
                top: "20%",
                right: "0%",
                left: "0%",
                bottom: "20%"
              }
      }
  },
  watch: {
    device() {
      this.loading = true;
      this.onStop();
      this.init();
    }
  },
  methods: {
    init() {
      this.loading = true;

      this.$nextTick()
        .then(() => {
          Quagga.init({
            inputStream: {
              name: 'Live',
              type: 'LiveStream',
              constraints: {
                facingMode: "environment",
                deviceId: this.device.deviceId,
                width: 1920,
                height: 1080
              },
              area: { 
                top: "30%",
                right: "0%",
                left: "0%",
                bottom: "30%"
              },
              target: document.querySelector('#scan'),
              singleChannel: true
            },
            debug: true,
            frequency: 10,
            decoder: {
              readers: [
                'code_128_reader'
              ],
              multiple: true,
              debug: {
                drawBoundingBox: true,
                showFrequency: true,
                drawScanline: true,
                showPattern: true
              },
            },
            numOfWorkers: navigator.hardwareConcurrency,
            locate: true,
            locator: {
              halfSample: true,
              patchSize: 'large'
            }
          }, (err) => {
            if (err) {
              this.error = err;
              console.log(err)
              return
            }
            
            Quagga.start();
            Quagga.onDetected(this.onDetected);

            this.loading = false;

            this.$forceUpdate();
          });
       });
    },
    onDetected(data) {
      try {

        if (Array.isArray(data))
          data = data[0];
        
        let valid = true;
        if (this.options && this.options.validate)
          valid = this.options.validate(this.tipo, data.codeResult.code, data.codeResult);
        
        if (valid) {
          this.code = data.codeResult.code;
          this.$emit('onDecode', this.code);
          this.close();
        }
      } catch(e) {
        console.error(e);
      }
    },
    onStop() {
      try {
        if (Quagga != null)
          Quagga.stop();
      } catch (e) {
        // console.error(e);
      }
    },
    close() {
      this.onStop();
      this.$emit('close');
    }
  },
  destroyed() {
    Quagga.stop();
  },
  mounted() {
    this.onStop();
    this.init();
  }
}
</script>
<style scoped lang="scss">
.code-dialog {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  background: white;
  text-align: center;
  padding-bottom: 4px;
  width: 100%;
  min-height: 200px;

  .tools {
    width: 100%;
  }
}
.overlay-element {
  position: absolute;
  top: 0px;
  width: 100%;
  height: calc(100% - 50px);
  background: rgba(30,30,30,0.5);
  clip-path: polygon(0% 0%, 100% 0%, 100% 30%, 0 30%, 0% 70%, 0% 100%, 100% 100%, 100% 70%, 0 70%);
}
.laser {
  background-color: rgb(243, 51, 37);
  height: 2px;
  position: absolute;
  z-index: 2;
  box-shadow: 0 0 4px red;
  margin-left: 0%;
  width: 90%;
  top: 45%;
}
</style>
<style lang="scss">
  video {
    width: 100%;
    height: auto;
  }
  canvas {
    display: none;
  }
</style>