<template>  
  <div>

    <v-card>
      <v-card-title class="text-center justify-center">
        <h3 class="font-weight-bold basil--text">
          {{$i18n.t('RastrearDocumentos')}}
        </h3>
      </v-card-title>

      <v-tabs
        v-model="tab"
        fixed-tabs
        background-color="white"
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows>
        <v-tabs-slider></v-tabs-slider>

        <v-tab v-if="logado" :class="{'tab-mobile': mobile}">{{$i18n.t('BuscaPorChave')}}</v-tab>
        <v-tab v-if="logado" :class="{'tab-mobile': mobile}">{{$i18n.t('BuscaPorNumero')}}</v-tab>
        <v-tab v-if="logado" :class="{'tab-mobile': mobile}">{{$i18n.t('BuscaPorRemetente')}}</v-tab>
        <v-tab v-if="logado" :class="{'tab-mobile': mobile}">{{$i18n.t('BuscaPorDestinatario')}}</v-tab>
        <v-tab v-if="logado" :class="{'tab-mobile': mobile}">{{$i18n.t('NrTransporte')}}</v-tab>
        <v-tab :class="{'tab-mobile': mobile}">{{$i18n.t('BuscaPorPedido')}}</v-tab>

      </v-tabs>

      <v-tabs-items v-model="tab" :touchless="mobile">

        <v-tab-item :key="1" v-if="logado">
          <busca ref="buscaChave" :small="true" tipo="chave">
            <div slot="append">
              <v-btn
                @click="showCamera('barcode')"
                icon>
                <v-icon>
                  mdi-barcode
                </v-icon>
              </v-btn>

              <v-btn
                @click="showCamera('qrcode')"
                icon>
                <v-icon>
                  mdi-qrcode
                </v-icon>
              </v-btn>
            </div>
          </busca>
        </v-tab-item>
     
        <v-tab-item :key="2" v-if="logado">
          <busca ref="buscaNumero" tipo="numero">
            <div slot="append">
              <v-btn
                @click="showCamera('barcode')"
                icon>
                <v-icon>
                  mdi-barcode
                </v-icon>
              </v-btn>

              <v-btn
                @click="showCamera('qrcode')"
                icon>
                <v-icon>
                  mdi-qrcode
                </v-icon>
              </v-btn>
            </div>
          </busca>
        </v-tab-item>
     
        <v-tab-item :key="3" v-if="logado">
          <busca tipo="remetente"></busca>
        </v-tab-item>
     
        <v-tab-item :key="4" v-if="logado">
          <busca tipo="destinatario"></busca>
        </v-tab-item>

        <v-tab-item :key="5" v-if="logado">
          <busca tipo="transporte"></busca>
        </v-tab-item>

        <v-tab-item :key="6">
          <busca ref="buscaPedido" tipo="pedido" :id="id">
            <div slot="append">
              <v-btn
                @click="showCamera('barcode')"
                icon>
                <v-icon>
                  mdi-barcode
                </v-icon>
              </v-btn>
              <v-btn
                @click="showCamera('qrcode')"
                icon>
                <v-icon>
                  mdi-qrcode
                </v-icon>
              </v-btn>
            </div>
          </busca>
        </v-tab-item>

      </v-tabs-items>

      <!--
      <div v-if="mobile">
        <busca tipo="pedido" :id="id" v-if="tab == 1"></busca>
        <busca tipo="chave" v-if="logado && tab == 2"></busca>
        <busca tipo="numero" v-if="logado && tab == 3"></busca>
        <busca tipo="remetente" v-if="logado && tab == 4"></busca>     
        <busca tipo="destinatario" v-if="logado && tab == 5"></busca>
      </div>
      -->
      <camera ref="camera" @change="leCodigoDeBarras" :tipo="tab" :formato="formato"></camera>

    </v-card>

  </div>
</template>

<script>
import Busca from './Busca.vue';
import Camera from '@/components/Camera.vue';
export default {
  components: { Busca, Camera },
    data: () => ({
      tab: 1,
      logado: false,
      id: null,
      scanning: false,
      mobile: window.innerWidth <= 800,
      formato: 'qrcode'
    }),
    methods: {
      leCodigoDeBarras(codigo) {
        try {
          switch(this.tab) {
            case 0:
              this.$refs.buscaPedido.leCodigoDeBarras(codigo);
              break;
            case 1:
              this.$refs.buscaChave.leCodigoDeBarras(codigo);
              break;
            case 2:
              this.$refs.buscaNumero.leCodigoDeBarras(codigo);
              break;
            default:
              break;
          }
        } catch(e){
          console.error(e);
        }
      },
      showCamera(formato) {
        this.formato = formato;
        this.$refs.camera.show();
      }
    },
    mounted() {
      this.id = this.$route.params.id;
      this.logado = this.$auth.authentication();
      if (!this.logado || this.id) {
        this.tab = 0;
      }
    }
  }
</script>
<style lang="css">
  .tab-mobile {
    font-size: 11px !important;
    font-weight: bold !important;
    white-space: nowrap !important;
  }

</style>