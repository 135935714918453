<template>  
  <div>
    <v-card>
        <v-card-title>
          <span class="text-h5">{{$i18n.t('Ocorrencia')}}</span>
        </v-card-title>
        
        <v-card-text>

          <v-row>
            <v-col cols="12" sm="12">
              <div class="text--primary">
                <label>{{$i18n.t('DataOcorrencia')}}</label>
                <div>{{ocorrencia.data}}</div>
              </div>
            </v-col>
          </v-row>
          
          <v-row>
            <v-col cols="12" sm="12">
              <div class="text--primary">
                <label>{{$i18n.t('TipoOcorrencia')}}</label>
                <div v-if="ocorrencia.ocorrencia">{{ocorrencia.ocorrencia.nome}}</div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12">
              <div class="text--primary">
                <label>{{$i18n.t('DescricaoOcorrencia')}}</label>
                <div>{{ocorrencia.descricao}}</div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12">
              <div class="text--primary">
                <label>{{$i18n.t('Situacao')}}</label>
                <div v-if="ocorrencia.situacao">{{ocorrencia.situacao.nome}}</div>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="ocorrencia.anexo && ocorrencia.anexo.tipo == 'IMAGE'">
            <v-col cols="12" sm="12">
              <div class="image-preview">
                <img width="100%" :src="ocorrencia.anexo.conteudo">
              </div>
            </v-col>
          </v-row>
          
        </v-card-text>

        <v-card-actions>
          <div class="button-tools">
            <v-btn @click="$emit('close')">{{$i18n.t('Fechar')}}</v-btn>
          </div>
        </v-card-actions>
    </v-card>
    
  </div>
</template>
<script>
export default {
    props: ['ocorrencia']
  }
</script>
<style lang="scss" scoped>
  .button-tools {
    width: 100%;
    text-align: center;
  }
</style>