<template>  
  <div class="content">
    
    <v-card
      class="mx-auto">
      <v-card-text>

        <v-row>
          <v-col cols="12" sm="12" align="center" v-if="logado && perfil != 'EMBARCADOR'">
            <nova :documento="documento" @salva="adiciona"></nova>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="12" sm="12">
            <div class="text--primary">
              <label>{{$i18n.t('NumeroNF')}}</label>
              <div>{{documento.numero}}</div>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" lg="6" sm="12">
            <div class="text--primary">
              <label>{{$i18n.t('Remetente')}}</label>
              <div>{{documento.remetenteCpfCnpj}} - {{documento.remetente}}</div>
            </div>
          </v-col>

          <v-col cols="6" lg="6" sm="12">
            <div class="text--primary">
              <label>{{$i18n.t('Destinatario')}}</label>
              <div>{{documento.destinatarioCpfCnpj}} - {{documento.destinatario}}</div>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" lg="6" sm="12">
            <div class="text--primary">
              <label>{{$i18n.t('CidadeInicio')}}</label>
              <div>{{documento.cidadeInicio}}</div>
            </div>
          </v-col>

          <v-col cols="6" lg="6" sm="12">
            <div class="text--primary">
              <label>{{$i18n.t('CidadeFim')}}</label>
              <div>{{documento.cidadeFim}}</div>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="12" sm="12">
            <div class="text--primary">
              <label>{{$i18n.t('DateEmissaoNF')}}</label>
              <div>{{documento.dataEmissao}}</div>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <div class="text--primary">
              <label>{{$i18n.t('Chave')}}</label>
              <div class="chave">{{documento.chave}}</div>
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div class="text--primary">
              <label>{{$i18n.t('TrackingID')}}</label>
              <div class="chave">{{documento.id}}</div>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" lg="4" sm="12">
            <div class="text--primary">
              <label>{{$i18n.t('NumeroTransporte')}}</label>
              <div>{{documento.numeroPedido}}</div>
            </div>
          </v-col>
       
          <v-col cols="8" lg="8" sm="12">
            <div class="text--primary">
              <label>{{$i18n.t('Transportador')}}</label>
              <div>{{documento.empresa.nome}}</div>
            </div>
          </v-col>
        </v-row>

      </v-card-text>
      
      <v-card-actions v-if="documento.ocorrencias && documento.ocorrencias.length > 0">
        <v-row>
          <v-col cols="12" sm="12">
            <div class="reponsive-content busca">

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        {{$i18n.t('Data')}}
                      </th>
                      <th class="text-left">
                        {{$i18n.t('TipoOcorrencia')}}
                      </th>
                      <!--
                      <th class="text-left">
                        {{$i18n.t('DescricaoOcorrencia')}}
                      </th>
                      -->
                      <th class="text-left">
                        {{$i18n.t('Situacao')}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="o, i in documento.ocorrencias"
                      :key="o.id"
                      :class="i % 2 == 0 ? 'clickable odd' : 'clickable even'"
                      :style="o.situacao.cor ? 'background: ' + o.situacao.cor : ''"
                      @click="visualiza(o)">
                      <td>{{ o.data }}</td>
                      <td>{{ o.ocorrencia.nome }}</td>
                      <!--<td>{{ o.descricao }}</td>-->
                      <td>{{ o.situacao.nome }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-col>
        </v-row>
      </v-card-actions>

    </v-card>

    <v-dialog
      v-model="visualizando"
      max-width="600px">

      <visualiza
        :ocorrencia="ocorrencia"
        @close="visualizando = false"
        v-if="ocorrencia">
      </visualiza>
      
    </v-dialog>

  </div>
</template>

<script>
import Nova from '@/pages/documentos/minhas/ocorrencias/Nova.vue';
import Visualiza from '@/pages/documentos/minhas/ocorrencias/Visualiza.vue';
export default {
  components: { Nova, Visualiza },
    props: ['documento'],
    data() {
      return {
        logado: false,
        perfil: 'VIAJANTE',
        visualizando: false,
        ocorrencia: null
      }
    },
    methods: {
      adiciona(ocorrencia) {
        if (this.documento.ocorrencias == null)
          this.documento.ocorrencias = [];
        this.documento.ocorrencias.push(ocorrencia);
        this.sort(1);
      },
      sort(coef) {
        this.documento.ocorrencias.sort((d1, d2) => {
          let a = this.$moment(d1.data, 'DD/MM/YYYY HH:mm:ss');
          let b = this.$moment(d2.data, 'DD/MM/YYYY HH:mm:ss');
          if (a < b) {
            return 1 * coef;
          } else if (a > b) {
            return -1 * coef;
          }
          return 0;
        });
      },
      visualiza(ocorrencia) {
        this.ocorrencia = ocorrencia;
        this.visualizando = true;
      }
    },
    mounted() {
      this.logado = this.$auth.authentication();
      if (this.logado)
        this.perfil = this.$auth.info().perfil;
    }
  }
</script>
<style lang="scss" scoped>
  .row {
    margin: 0px;
  }
  .chave {
    font-size: 14px;
  }
  .content {
    margin-bottom: 20px;
  }
  .clickable {
    cursor: pointer;
    
    &.odd {
      background: rgba(0,0,0,0.01);
    }
  }
</style>