<template>  
  <div>

    <v-dialog
      v-model="scanning"
      persistent
      v-if="loaded">

      <div class="camera-content" v-if="scanning">

        <quagga
          :device="devices[index]"
          @close="scanning = false"
          @onDecode="onDecode"
          @changeDevice="changeDevice"
          :options="options"
          :tipo="tipo"
          v-if="tipo == 1 && formato == 'barcode'">
        </quagga>

        <zxing
          :device="devices[index]"
          @close="scanning = false"
          @onDecode="onDecode"
          @changeDevice="changeDevice"
          :options="options"
          :tipo="tipo"
          :format="formato"
          v-if="tipo == 0 || (tipo == 1 && formato == 'qrcode') || tipo == 2">
        </zxing>

      </div>

    </v-dialog>

  </div>
</template>

<script>
import Quagga from './camera/Quagga.vue';
import Zxing from './camera/Zxing.vue';
export default {
  components: { Quagga, Zxing },
    props: ['tipo', 'formato'],
    data() {
      return {
        loaded: false,
        scanning: false,
        devices: [],
        index: 0,
        options: {
          validate: function (tipo, code, result) {
            if (tipo == 1)
              return code.length == 44 && result.format == 'code_128';
            return true;
          }
        }
      }
    },
    watch: {
      tipo() {
        this.scanning = false;
      }
    },
    methods: {
      onDecode(code) {
        this.$emit('change', code);
      },
      changeDevice() {
        this.index++;
        if (this.index >= this.devices.length)
          this.index = 0;
      },
      show() {
        if (this.devices.length <= 0)
          this.load(() => {
            this.scanning = true;
          });
        else
          this.scanning = true;
      },
      load(callback) {

        navigator.mediaDevices.getUserMedia({video:true})
        .then((stream) => {

          stream.getTracks().forEach((track) => {
            if (track.kind == 'video')
              track.stop();
          });
        
          navigator.mediaDevices.enumerateDevices()
            .then((devices) => {
              this.index = -1;
              let nIndex = 0;

              if (devices && devices.length > 0) {
                devices.forEach((device) => {
                  // if ( device.kind === 'videoinput' && device.label.match(/back/) != null ) {
                  if (device && device.deviceId != "" && device.kind === 'videoinput') {

                    this.devices.push(device);
                    nIndex = this.devices.length - 1;

                    if (device.label.match(/back/) != null)
                      this.index = this.devices.length - 1;
                    
                  }
                })

                if (this.index < 0)
                  this.index = nIndex;
                
                this.loaded = true;
                if (callback)
                  callback();
              }
              
            });

        })
        .catch( (err) =>{
          console.error(err);
        });
      }
    },
    mounted() {

      //(async () => {
      //      await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      //      let devices = await navigator.mediaDevices.enumerateDevices();
      //      console.log(devices);
      //  })();

      //navigator.mediaDevices.getUserMedia({audio:true, video:true})
      //  .then(() => {
      //      this.load();
      //  })
      //  .catch( (err) =>{
      //      console.log(err);
      //  });
    
      this.load();
    }
  }
</script>
<style scoped lang="scss">
  .camera-content {
    min-height: 200px;
    border: 3px solid black;
    background: rgb(19, 19, 19);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    text-align: center; 
  }
</style>