<template>  
  <div class="container">

    <v-text-field
      ref="busca"
      :class="{small: small}"
      v-model="busca"
      :label="$i18n.t('Buscar')"
      required
      @keydown="keydown">

      <v-btn
        slot="append"
        icon
        @click="rastreia"
        v-if="!loading">
        <v-icon dark>
          mdi-card-search
        </v-icon>
      </v-btn>
      
      <div slot="append" v-if="!loading">
        <slot name="append"></slot>
      </div>

      <v-progress-circular
        slot="append"
        indeterminate
        color="primary"
        v-if="loading">
      </v-progress-circular>
      
    </v-text-field>
    
    <span v-if="iniciado && documentos.length == 0 && !loading">
      {{ $i18n.t('NenhumDocumentoEncontrado') }}
    </span>
    <documento v-for="doc in documentos" :key="doc.id" :documento="doc"></documento>
    
  </div>
</template>

<script>
import Documento from './Documento.vue';
  export default {
  components: { Documento },
    props: ['tipo', 'id', 'small'],
    data: () => ({
      busca: '',
      documentos: [],
      iniciado: false,
      loading: false
    }),
    methods: {
      leCodigoDeBarras(codigo) {
        this.busca = codigo;
        this.rastreia();
      },
      rastreia() {
        if (this.busca != '') {
          this.loading = true;
          this.iniciado = true;
          /*
          if (this.tipo == 'pedido') {
            this.$http.get(`/api/doc/documentos/rastreio/pedido/${this.busca}`)
                .then((req) => {
                    this.documentos = req.data;
                })
                .catch((e) => {
                  this.$msg.erro(e.response.data.message);
                })
                .finally(() => {
                  this.loading = false;
                });
          }
          else {
            */
            // this.$http.post('/api/doc/documentos/rastreio', {tipo: this.tipo, valor: this.busca})
            this.$http.get(`/api/doc/documentos/rastreio/${this.tipo}/${this.busca}`)
                .then((req) => {
                  //if (req.data.sucesso)
                    this.documentos = req.data;
                  //else
                    //this.$msg.erro(req.data.mensagem); 
                })
                .catch((e) => {
                  this.$msg.erro(e.response.data.message);
                })
                .finally(() => {
                  this.loading = false;

                  this.$nextTick(() => {
                    this.$refs.busca.blur();
                  });
                });
          // }
        } else {
          this.$refs.busca.focus();
        }
      },
      keydown(e) {
          if (e.key == 'Enter')
            this.rastreia();
      }
    },
    mounted() {
      this.$refs.busca.focus();
      if (this.id != null) {
        this.busca = this.id;
        this.rastreia();
      }
    }
  }
</script>
<style lang="scss" scoped>
  
  @media (max-width:640px) {
    .small {
      font-size: 11.5px;
    }
  }

  @media (max-width:440px) {
    .small {
      font-size: 10.5px;

      .v-btn--icon.v-size--default {
        width: 12px;
      }
    }
  }
</style>